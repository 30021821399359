import { Query } from '@feathersjs/feathers';
import { array, boolean, date, InferType, number, object, string } from 'yup';
import moment from 'moment';
import { stringify } from 'qs';
import {
  hashParamsRowSelection,
  stringifyOptions,
  queryParamsColumnVisibility,
  queryParamsPagination,
  queryParamsSorting,
} from '@shiftsmartinc/remix-utils';
import { SelectSingleOption } from '@shiftsmartinc/shiftsmart-ui';
import { UserShift } from '~/routing/shifts/actions/getShifts';

const tabs = ['atRisk', 'inProgress', 'floaters', 'map'] as const;
type Tab = (typeof tabs)[number];

const redundantShiftModes = ['true', 'false', 'rollup', 'nest'] as const;
type RedundantShiftMode = (typeof redundantShiftModes)[number];

const shiftTypes = [
  'ambassador',
  'dryRun',
  'orientation',
  'standard',
  'training',
] as const;
export type ShiftType = (typeof shiftTypes)[number];
export const shiftTypesDefault: ShiftType[] = ['standard'];
export const DETAILS_SIZES = ['sm', 'md', 'lg'] as const;
export type DetailsSize = (typeof DETAILS_SIZES)[number];

export const shiftQueryParams = object({
  dateShiftEnd: date(),
  dateShiftStart: date(),
  detailsSize: string<DetailsSize>().oneOf(DETAILS_SIZES),
  drawerGeofenceTemplateIds: array(string().defined().required()),
  forecastedFillRateMax: number().min(0).max(1),
  forecastedFillRateMin: number().min(0).max(1),
  geofenceTemplateIds: array(string().defined().required().min(1)).min(1),
  hasMobileBackups: boolean(),
  msas: array(string().defined().required().min(1)).min(1),
  operatorId: string(),
  redundantShiftMode: string<RedundantShiftMode>().oneOf(redundantShiftModes),
  search: string()
    .label('Searches by partner uuid, partner name, and shift title')
    .min(1),
  seenMax: number().min(0).max(50),
  seenMin: number().min(0).max(50),
  sentMax: number().min(0).max(50),
  sentMin: number().min(0).max(50),
  shiftTypes: array(
    string<ShiftType>().oneOf(shiftTypes).defined().required().min(1),
  ).min(1),
  statuses: array(string().defined().required().min(1)).min(1),
  storeIds: array(string().defined().required().min(1)).min(1),
  tab: string<Tab>().oneOf(tabs),
})
  .concat(queryParamsPagination)
  .concat(queryParamsSorting)
  .concat(queryParamsColumnVisibility);

export type ShiftQueryParams = InferType<typeof shiftQueryParams>;

const shiftQueryParamsDefault = {
  // These each correspond to a column.id in the table
  hiddenColumns: ['msa'],
  sorts: ['asc-start', 'asc-forecastedFillRate'],
} satisfies ShiftQueryParams;

export const shiftQueryStringDefault = stringify(
  shiftQueryParamsDefault,
  stringifyOptions,
);

export const allShiftsQueryParams: ShiftQueryParams = {
  ...shiftQueryParamsDefault,
  forecastedFillRateMax: undefined,
  forecastedFillRateMin: undefined,
  page: undefined,
  search: undefined,
  statuses: undefined,
  tab: undefined,
};

export const atRiskQueryParams: ShiftQueryParams = {
  forecastedFillRateMax: undefined,
  forecastedFillRateMin: undefined,
  hiddenColumns: ['msa'],
  page: undefined,
  search: undefined,
  statuses: undefined,
  tab: 'atRisk',
};

export const inProgressQueryParams: ShiftQueryParams = {
  forecastedFillRateMax: undefined,
  forecastedFillRateMin: undefined,
  hiddenColumns: ['msa'],
  page: undefined,
  search: undefined,
  statuses: undefined,
  tab: 'inProgress',
};

export const floatersQueryParams: ShiftQueryParams = {
  forecastedFillRateMax: undefined,
  forecastedFillRateMin: undefined,
  hiddenColumns: ['msa'],
  page: undefined,
  search: undefined,
  statuses: undefined,
  tab: 'floaters',
};

export const geofencesQueryParams: ShiftQueryParams = {
  forecastedFillRateMax: undefined,
  forecastedFillRateMin: undefined,
  hiddenColumns: ['msa'],
  page: undefined,
  search: undefined,
  statuses: undefined,
  tab: 'map',
};

export const redundantShiftModeOptions: SelectSingleOption[] = [
  { name: 'Rollup', value: 'rollup' },
  { name: 'Nest', value: 'nest' },
  { name: 'Show', value: 'true' },
  { name: 'Ignore', value: 'false' },
];

const limit = 100;

export const queryParamsToQueryFilters = (queryParams: ShiftQueryParams) => {
  const {
    search,
    statuses,
    operatorId,
    redundantShiftMode,
    sorts,
    msas,
    storeIds,
    geofenceTemplateIds,
    page = 0,
    hasMobileBackups,
    tab,
    shiftTypes = shiftTypesDefault,
  } = queryParams;
  const { seenMax, seenMin, sentMax, sentMin } = queryParams;
  const { forecastedFillRateMax, forecastedFillRateMin } = queryParams;
  const { dateShiftEnd, dateShiftStart } = queryParams;

  const query: Query = {
    $limit: limit,
    $skip: limit * page,
    $sort: sorts?.reduce(($sort, sort) => {
      type Sort = ['asc' | 'desc', keyof UserShift];
      const [direction, id] = sort.split('-') as Sort;
      return { ...$sort, [id]: direction === 'desc' ? -1 : 1 };
    }, {}),
    redundantShiftMode: redundantShiftMode || 'nest',
    shiftType: { $in: shiftTypes },
  };

  if (tab && tab !== 'map' && tab !== 'floaters') {
    query.presetFilter = tab;
  }

  if (tab === 'floaters') query.redundancyType = 'floater';
  else if (tab !== 'map') query.redundancyType = { $nin: ['floater'] };

  if (hasMobileBackups) query.hasMobileBackups = true;

  if (dateShiftEnd) {
    query.end = moment(dateShiftEnd ?? dateShiftStart).format('YYYY-MM-DD');
  }

  if (dateShiftStart) {
    query.start = moment(dateShiftStart).format('YYYY-MM-DD');
  }

  if (statuses?.length) query.status = { $in: statuses };

  if (operatorId) query.operatorId = operatorId;

  if (forecastedFillRateMin) {
    query.forecastedFillRate = {
      ...query.forecastedFillRate,
      $gte: forecastedFillRateMin,
    };
  }
  if (forecastedFillRateMax) {
    query.forecastedFillRate = {
      ...query.forecastedFillRate,
      $lte: forecastedFillRateMax,
    };
  }

  if (seenMin) {
    query.seen = { ...query.seen, $gte: seenMin };
  }
  if (seenMax) {
    query.seen = { ...query.seen, $lte: seenMax };
  }

  if (sentMin) {
    query.sent = { ...query.sent, $gte: sentMin };
  }
  if (sentMax) {
    query.sent = { ...query.sent, $lte: sentMax };
  }

  if (geofenceTemplateIds?.length) {
    query.geofenceTemplateId = { $in: geofenceTemplateIds };
  }

  if (msas?.length) query.zoneIds = { $in: msas };

  if (storeIds?.length) query.siteId = { $in: storeIds };

  if (search) query.searchString = search;

  return query;
};

const modals = ['escalation'] as const;
type Modal = (typeof modals)[number];
export const shiftTableHashSchema = object({
  escalationModalEscalationId: string().optional(),
  escalationModalShiftId: string().when('modal', {
    is: ('escalation' satisfies Modal) && 'escalationModalEscalationId',
    then: (schema) => schema.defined().required(),
  }),
  modal: string<Modal>().oneOf(modals),
}).concat(hashParamsRowSelection);

export type ShiftHashParams = InferType<typeof shiftTableHashSchema>;
